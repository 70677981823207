var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "form" },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c("v-card-title", { staticClass: "text-center" }, [
                _vm._v("Actualiser les données des entreprises manuelles"),
              ]),
              _c("upload-error", { attrs: { error: _vm.error } }),
              _c(
                "v-card-text",
                [
                  _c("v-file-input", {
                    attrs: {
                      label: "Choisissez un fichier",
                      "prepend-icon": "mdi-paperclip",
                      accept: ".xlsx",
                    },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-large",
                      attrs: { color: "primary", disabled: _vm.loading },
                      on: { click: _vm.ImportData },
                    },
                    [_vm._v("Import Data")]
                  ),
                  _vm.loading
                    ? _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }